function Form({ dispatch }) {
  const handleSubmit = (event) => {
    event.preventDefault();
   
    const formData = new FormData(event.target);
    console.log(formData);

    console.log(document.getElementById("nome").value)
    console.log(document.getElementById("email").value)
    console.log(document.getElementById("empresa").value)
    console.log(document.getElementById("contato").value)
    console.log(document.getElementById("cargo").value)
    const data = {
       email: document.getElementById("email").value,
       nome: document.getElementById("nome").value,
       empresa: document.getElementById("empresa").value,
       contato: document.getElementById("contato").value,
       cargo: document.getElementById("cargo").value
    };
   
    fetch('https://api.impostograma.com.br/ImpostoGrama/api/CadastroQuiz/CreateCadastroQuiz', {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy'
       },
       body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(() => {
        dispatch({ type: "resetForm" });
    })
    .catch((error) => {
       console.error('Error:', error);
    });
   };
  return (
    <>
      <h4>Preencha seu cadastro e venha ser um parceiro IT Works!</h4>

      <form id="meuFormulario" className="form" encType="multipart/form-data" onSubmit={handleSubmit}>
        <input type="text" id="nome" name="nome" className="form-input" placeholder="Nome:" required></input>

        <input type="text" id="cargo" name="cargo" className="form-input" placeholder="Cargo:" required></input>

        <input type="text" id="empresa" name="empresa" className="form-input" placeholder="Empresa:" required></input>

        <input type="tel" id="contato" name="contato" className="form-input" placeholder="Contato:" required></input>

        <input type="email" id="email" name="email" className="form-input" placeholder="E-mail:" required></input>

        <button
          className="btn btn-ui"
          type="submit"
          value="save"
        >
          Enviar
        </button>
      </form>
    </>
  );
}

export default Form;