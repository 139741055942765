function ResetForm({ dispatch }) {
  return (
    <>
      <h3 className="align-center" >Obrigado por participar do nosso Quiz<br/>Nos vemos em breve!</h3>
      <button
        className="btn btn-ui"
        onClick={() => dispatch({ type: "restart" })}
      >
        Refazer Quiz
      </button>
    </>
  );
}

export default ResetForm;