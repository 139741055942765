function StartScreen({ numQuestions, dispatch }) {
  return (
    <div className="start">
      <h2>Bem vindo ao quiz da<br/>IT Works</h2>
      <h3>{numQuestions} questões para acrescentar aos seus conhecimentos de inteligência fiscal.</h3>
      <button
        className="btn btn-ui"
        onClick={() => dispatch({ type: "start" })}
      >
        Vamos começar!
      </button>
    </div>
  );
}
export default StartScreen;
