import Feedback from "./Feedback";
import Options from "./Options";

function Questions({ question, dispatch, answer, isCorrect }) {
  return (
    <div>
      <h3>{question.question}</h3>
      <Options question={question} dispatch={dispatch} answer={answer} />
      {answer !== null && <Feedback question={question} answer={answer} isCorrect={isCorrect} />}
    </div>
  );
}

export default Questions;
