function FinishScreen({ points, maxPoints, dispatch }) {
  const percentage = (points / maxPoints) * 100;
  let emoji;
  let mensagem;
  let parceiro = "Venha ser um parceiro IT Works!";
  if (percentage === 100) emoji = "🎖️";
  if (percentage === 100) mensagem = "Parabéns, você acertou todas as questões"
  if (percentage < 100) mensagem = "Aprenda mais sobre Inteligência fiscal"

  return (
    <>
      <p className="result">
        <span>{emoji}</span>
        Você pontuou <strong>{points}</strong> de {maxPoints} (
        {Math.ceil(percentage)}%)
      </p>
      <p className="highscore">
        {mensagem} <br /> {parceiro}
      </p>
      <button
        className="btn btn-ui"
        onClick={() => dispatch({ type: "form" })}
      >
        Próxima Etapa
      </button>
      <button
        className="btn btn-left"
        onClick={() => dispatch({ type: "restart" })}
      >
        Refazer Quiz
      </button>
    </>
  );
}

export default FinishScreen;
