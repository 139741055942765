function Feedback({ question, answer, isCorrect }) {
  let feedbackText = "";

 console.log({ question, answer, isCorrect })

  if (answer === question.correctOption) {
     feedbackText = question.right;
  } else {
     const wrongFeedbackLetters = ["wrongA", "wrongB", "wrongC", "wrongD", "wrongE"];
     const wrongFeedbackLetter = wrongFeedbackLetters[answer];
     feedbackText = question[wrongFeedbackLetter];
  }
 
  return (
     <div>
       <h4>{feedbackText}</h4>
     </div>
  );
 }
 
 export default Feedback;
 